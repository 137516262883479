import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import { _call } from "../utils/api";
import { ACCESSKEY, USERCOLUMNS, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from "moment/moment";
import { useLocation } from "react-router-dom";
import { Box, Button, ButtonGroup, Modal } from "@mui/material";
import { handleExpiryDate } from "../utils/_function";
import { imageURL } from "../utils/_constant";

const _initial = {
    state: false,
    subject: '',
    description: ''
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


const ViewUserScreen = (props) => {
    const location = useLocation();
    const data = location.state.data;
    const [loader, setloader] = useState(false);
    const [analytics, setanalytics] = useState([]);
    const [skills, setskills] = useState([]);
    const [userIris, setuserIris] = useState([]);
    const [rejectionEmail, setrejectionEmail] = useState(_initial);
    const [userBilling, setuserBilling] = useState(false);
    const [userDocs, setuserDocs] = useState([])

    useEffect(() => {
        handleGetAnalytics();
        handleData();
        return () => { }
    }, []);

    const handleData = () => {

        if (data.user_type == "Seller") {
            handleGetSkillsDocs();
        }
    }

    const handleGetBankInfo = async () => {
        try {
            const getbankInfo = await _call(`admin/b-info/${data.id}`, 'GET');
            setuserBilling(getbankInfo.data)
        } catch (err) {
            console.log(err)
        }
    }

    const handleGetAnalyticsInfo = async () => {
        try {
            const _data = {
                id: data.id
            }
            const _getAnalytics = await _call(`admin-user/${ACCESSKEY}/`, 'POST', _data);
            setanalytics(_getAnalytics.data);
        } catch (err) {
            console.log(err)
        }
    }

    const handleGetIrisInfo = async () => {
        try {
            const getIris = await _call('get-iris-user', 'POST', {
                userId: data.id
            });
            setuserIris(getIris.data)
        } catch (err) {
            console.log(err)
        }
    }

    const handleGetDocs = async () => {
        try {
            const getUserDocs = await _call(`admin/get-docs/${data.id}`, 'GET');
            setuserDocs(getUserDocs.data);
        } catch (err) {
            console.log(err)
        }
    }


    const handleGetAnalytics = async () => {
        try {
            setloader(true);
            const _data = {
                id: data.id
            }
            handleGetBankInfo();
            handleGetAnalyticsInfo();
            handleGetDocs();
            handleGetIrisInfo();
            // const getbankInfo = await _call(`admin/b-info/${data.id}`, 'GET');
            // setuserBilling(getbankInfo.data)

            // const _getAnalytics = await _call(`admin-user/${ACCESSKEY}/`, 'POST', _data);

            // const getIris = await _call('get-iris-user', 'POST', {
            //     userId: data.id
            // });
            // const getUserDocs = await _call(`admin/get-docs/${data.id}`, 'GET');
            // setuserDocs(getUserDocs.data);

            // setuserIris(getIris.data)
            // setanalytics(_getAnalytics.data);
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }
    }

    const handleGetSkillsDocs = async () => {
        try {
            setloader(true);
            const _data = {
                id: data.id
            }
            const _getSkills = await _call(`admin-user/${ACCESSKEY}`, 'PATCH', _data);
            setskills(_getSkills.data[0]);

            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }
    }

    const handlePending = async () => {

        const _dataset = {
            userId: analytics.user_info.id,
            type: 1,
            status: '22'
        }
        try {
            setloader(true);
            const _resolveOperation = await _call(`admin-user/${ACCESSKEY}`, 'PUT', _dataset);
            const emailSender = await _call('common-email', 'PUT', {
                userId: analytics.user_info.id,
                topic: 'META_CHANGE'
            })
            setanalytics({
                ...analytics,
                user_info: {
                    ...analytics.user_info,
                    status: 'Pending'
                }
            })
            setloader(false);

            alert('Successfully updated');
        } catch (err) {
            console.log(err)
        }
    }

    const handleOperation = async (type, mode) => {
        try {
            var _data = {};

            if (mode == 'profile') {
                _data['userId'] = analytics.user_info.id;
                _data['type'] = 1;
                _data['status'] = type == 1 ? '00' : '11'
            } else {
                _data['userId'] = skills.id;
                _data['type'] = 0;
                _data['status'] = type == 1 ? '00' : '11'
            }

            setloader(true);

            const _resolveOperation = await _call(`admin-user/${ACCESSKEY}`, 'PUT', _data);

            if (mode == 'profile' && type == 1) {
                const emailSender = await _call('common-email', 'PUT', {
                    userId: analytics.user_info.id,
                    topic: 'ACCOUNT_ACCEPTANCE'
                })
            }

            if (mode != 'profile') {
                const emailSender = await _call('common-email', 'PUT', {
                    userId: analytics.user_info.id,
                    topic: type == 1 ? 'SKILL_ACCEPTANCE' : 'SKILL_REJECTION'
                })
            }

            setloader(false);

            alert('Successfully updated');
            if (mode == 'profile') {
                setanalytics({
                    ...analytics,
                    user_info: {
                        ...analytics.user_info,
                        status: type == 1 ? 'Active' : 'REJECTED'
                    }
                })
            } else {
                setskills({
                    ...skills,
                    is_activated: type == 1 ? 1 : 0
                })
            }

        } catch (err) {
            setloader(false);
            console.log(err);
        }
    }

    const handleOnOperation = async () => {
        // email, subject, message, name

        try {
            setrejectionEmail(_initial);
            const _rejectionEmail = await _call(`admin-email/${ACCESSKEY}`, 'POST', {
                subject: rejectionEmail?.title,
                message: rejectionEmail?.description,
                name: analytics?.user_info?.name,
                email: analytics?.user_info?.email,
                rejection: true
            });

            handleOperation(0, 'profile');

        } catch (err) {
            console.log(err);
        }

    }

    const handleStatus = (index) => {
        if (index == 0) {
            if (analytics?.user_info?.status == 'APPROVED' || analytics?.user_info?.status == 'A' || analytics?.user_info?.status == 'Active' || analytics?.user_info?.status == 'active') return 'contained';
        }
        if (index == 1) {
            if (analytics?.user_info?.status == 'Pending' || analytics?.user_info?.status == 'P') return 'contained';
        }
        if (index == 2) {
            if (analytics?.user_info?.status?.toLowerCase() == 'rejected') return 'contained';
        }
        return 'outlined';
    }

    return (
        <Wrapper>

            <div className="row m-0" style={{ marginTop: -20 }}>
                <div className="col-md-10 offset-md-1">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-0">User Details</h4>
                    <p className="mt-0 mb-3">User Detail Screen. Do the necessary actions</p>
                    <div className="row m-0 mt-4 p-0">
                        <div className="col-md-4 m-0 p-0">
                            <div className="card p-4 shadow-md">
                                <h4 style={{ fontWeight: 'bold' }} className="mb-0">User Information</h4>
                                <p className="mt-0 mb-3">User basic informations are listed under,</p>
                                <div className="mt-2" />
                                <img />
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Name:</h5>
                                <p className="mt-1">{analytics?.user_info?.name}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Email:</h5>
                                <p className="mt-1">{analytics?.user_info?.email}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Address:</h5>
                                <p className="mt-1">{analytics?.user_info?.address}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Type:</h5>
                                <p className="mt-1">{analytics?.user_info?.user_type}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Social Security Number:</h5>
                                <p className="mt-1">{analytics?.user_info?.sns}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Gender:</h5>
                                <p className="mt-1">{analytics?.user_info?.gender == 0 ? "Female" : "Male"}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>About:</h5>
                                <p className="mt-1">{analytics?.user_info?.bio}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Date of Birth:</h5>
                                <p className="mt-1">{moment(analytics?.user_info?.dob).format('DD-MM-YYYY')}</p>
                                {/* {analytics?.user_info?.status != 'APPROVED' &&
                                    <Button
                                        onClick={() => handleOperation(1, 'profile')}
                                        className="w-100"
                                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                                    >
                                        Approve
                                    </Button>
                                }
                                <Button
                                    onClick={() => setrejectionEmail({ ...rejectionEmail, state: true })}
                                    className="w-100 mt-1"
                                    style={{ color: "#252525", fontWeight: 'bold' }}
                                >
                                    Reject
                                </Button> */}
                                <ButtonGroup variant="contained" aria-label="outlined primary button group" className="mb-3">
                                    <Button className="w-100" onClick={() => handleOperation(1, 'profile')} variant={handleStatus(0)}>Approved</Button>
                                    <Button className="w-100" onClick={() => handlePending()} variant={handleStatus(1)}>Pending</Button>
                                    <Button className="w-100" onClick={() => setrejectionEmail({ ...rejectionEmail, state: true })} variant={handleStatus(2)}>Rejected</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        {analytics?.user_info?.user_type == 'Seller' && <>
                            <div className="col-md-4 p-0">
                                {!_.isEmpty(skills) && <>
                                    <div className="card p-4 shadow-md">
                                        <h4 style={{ fontWeight: 'bold' }} className="mb-0">User Documentation</h4>
                                        <p className="mt-0 mb-3">User document informations are listed under,</p>

                                        <div className="mt-2" />
                                        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Skill Status:</h5>
                                        <p className="mt-1">{skills?.is_activated ? 'Approved' : 'Rejected'}</p>
                                        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>License Expiry Date:</h5>
                                        <p className="mt-1">{handleExpiryDate(skills.license_expiry_date)}</p>
                                        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>MC Number:</h5>
                                        <p className="mt-1">{skills.mc_number}</p>
                                        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>Dot Number:</h5>
                                        <p className="mt-1">{skills.dot_number}</p>
                                        <h5 className="mb-0 mt-1" style={{ fontWeight: 'bold' }}>Vehicle Registration Copy:</h5>
                                        <p className="mt-1">Download File</p>
                                        <Button
                                            onClick={() => window.open(imageURL + skills.fileName1)}
                                            className="w-100"
                                            style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 40 }}
                                        >
                                            Download
                                        </Button>
                                        <h5 className="mb-0 mt-4" style={{ fontWeight: 'bold' }}>Driver Insurance Copy:</h5>
                                        <p className="mt-1">Download File</p>
                                        <Button
                                            onClick={() => window.open(imageURL + skills.fileName2)}
                                            className="w-100"
                                            style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 40 }}
                                        >
                                            Download
                                        </Button>
                                        <h5 className="mb-0 mt-4" style={{ fontWeight: 'bold' }}>Driver License Front:</h5>
                                        <p className="mt-1">Download File</p>
                                        <Button
                                            onClick={() => window.open(imageURL + skills.fileName3)}
                                            className="w-100"
                                            style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 40 }}
                                        >
                                            Download
                                        </Button>
                                        <h5 className="mb-0 mt-4" style={{ fontWeight: 'bold' }}>Driver License Back:</h5>
                                        <p className="mt-1">Download File</p>
                                        <Button
                                            onClick={() => window.open(imageURL + skills.fileName4)}
                                            className="w-100"
                                            style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 40 }}
                                        >
                                            Download
                                        </Button>

                                        <h5 className="mb-0 mt-4" style={{ fontWeight: 'bold' }}>Additional Documents:</h5>
                                        <p className="mt-1">Download File</p>
                                        {userDocs?.map((item, index) => {
                                            return <Button
                                                onClick={() => window.open(imageURL + item.fileLink)}
                                                className="w-100 mt-2"
                                                style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 40 }}
                                            >
                                                Download
                                            </Button>
                                        })}

                                        <h5 className="mb-0 mt-4" style={{ fontWeight: 'bold' }}>Skill Document:</h5>

                                        {skills?.is_activated != 1 &&
                                            <Button
                                                onClick={() => handleOperation(1, 'skill')}
                                                className="w-100 mt-4"
                                                style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                                            >
                                                Approve Skill
                                            </Button>
                                        }
                                        <Button
                                            onClick={() => handleOperation(0, 'skill')}
                                            className="w-100 mt-1"
                                            style={{ color: "#252525", fontWeight: 'bold' }}
                                        >
                                            Reject Skill
                                        </Button>
                                    </div>
                                </>}
                            </div>
                        </>}

                        <div className="col-md-4 p-0">
                            <div className="card p-4 shadow-md">
                                <h4 style={{ fontWeight: 'bold' }} className="mb-0">User Analytics</h4>
                                <p className="mt-0 mb-3">User document informations are listed under,</p>
                                <div className="mt-2" />
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Status</h5>
                                <p className="mt-1">{analytics?.user_info?.status ? analytics?.user_info?.status : 'In-Active'}</p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Rating</h5>
                                <p className="mt-1">{analytics?.user_info?.ratings} <span style={{ fontSize: 12 }}>Out of 5</span></p>
                                {analytics.user_info?.user_type == "Buyer" &&
                                    <>
                                        <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Posted Job</h5>
                                        <p className="mt-1">{analytics?.total_jobs}</p>
                                    </>
                                }
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Completed Job</h5>
                                <p className="mt-1">{analytics?.completed_jobs} <span style={{ fontSize: 12 }}>Jobs</span></p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Job in Progress</h5>
                                <p className="mt-1">{analytics?.pending_jobs ? analytics?.pending_jobs : 0} <span style={{ fontSize: 12 }}>Jobs</span></p>
                                <h5 className="mb-0" style={{ fontWeight: 'bold' }}>User Bank Account</h5>
                                <p className="mt-1">{userBilling ? 'Connected' : 'Not Connected'}</p>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-4 gap-4 mt-4 bg-white p-4">
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">First Name</h4>
                            <h4>{userIris[userIris.length - 1]?.first_name}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Last Name</h4>
                            <h4>{userIris[userIris.length - 1]?.last_name}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Middle Name</h4>
                            <h4>{userIris[userIris.length - 1]?.middle_name}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Suffix</h4>
                            <h4>{userIris[userIris.length - 1]?.suffix}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">SSN / TIN Type</h4>
                            <h4>{userIris[userIris.length - 1]?.tin_type}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">SSN / TIN</h4>
                            <h4>{userIris[userIris.length - 1]?.ssn_tin}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Address</h4>
                            <h4>{userIris[userIris.length - 1]?.address}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">City</h4>
                            <h4>{userIris[userIris.length - 1]?.city}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Country</h4>
                            <h4>{userIris[userIris.length - 1]?.country}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Postal Code</h4>
                            <h4>{userIris[userIris.length - 1]?.postal_code}</h4>
                        </div>
                        <div className="bg-slate-50 p-3">
                            <h4 className="font-bold text-lg">Phone Number</h4>
                            <h4>{userIris[userIris.length - 1]?.phone_number}</h4>
                        </div>

                    </div>
                </div>

            </div>


            <Modal
                open={rejectionEmail.state}
                onClose={() => setrejectionEmail({ ...rejectionEmail, state: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, background: '#f7f7f7' }}>
                    <h4 id="parent-modal-title" style={{ fontWeight: 'bold' }} className="mb-0">Send User Rejection</h4>
                    <br />
                    <input type="" value={rejectionEmail?.title} onChange={e => setrejectionEmail({ ...rejectionEmail, title: e.target.value })} style={{ height: 50 }} placeholder="Subject" className="form-control" />
                    <br />
                    <textarea value={rejectionEmail?.description} onChange={e => setrejectionEmail({ ...rejectionEmail, description: e.target.value })} className="form-control" rows={10}></textarea>
                    <br />
                    <Button
                        onClick={handleOnOperation}
                        className="w-100"
                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>


        </Wrapper>
    );
}

export default ViewUserScreen;