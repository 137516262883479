import React, { useRef, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { TocryptHash } from '../utils/_function'
import { _call } from '../utils/api';
import { firebaseConfig } from '../utils/_constant';

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSENGER_ID,
//     appId: process.env.REACT_APP_APP_ID,
//     measurementId: process.env.REACT_APP_MEASUREMENT_ID
// }


const keyId = process.env.REACT_APP_SECRET_KEY;

const LoginScreen = (props) => {
    const emailRef = useRef('');
    const passwordRef = useRef('');
    const navigate = useNavigate();
    initializeApp(firebaseConfig)

    const auth = getAuth();

    async function onSubmit() {

        try {

            const login = await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value);
            const encryptId = TocryptHash(keyId, login.user.uid);

            const loggedInUserToken = await _call('login', 'POST', {
                guid: login.user.uid
            });

            const token = loggedInUserToken.data;
            localStorage.setItem('token', token);

            const _authenticateUser = await _call('authenticate', 'POST', {
                uid: encryptId
            }, {
                Authorization: 'Bearer ' + token
            });



            navigate('/');

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="container">
            <div className="row">
                <div className="offset-md-3 col-md-6 mt-5 pt-5">
                    <div className="mt-5 pt-5" />
                    <div className="mt-5 pt-5" />
                    <div className='card p-4' style={{ backgroundColor: '#f7f7f7' }}>
                        <label>Email Address</label>
                        <input type="email" ref={emailRef} className='form-control' />
                        <div className="mt-4" />
                        <label>Password</label>
                        <input type="password" ref={passwordRef} className='form-control' />
                        <div className="mt-4" />
                        <button onClick={onSubmit} className='btn btn-primary'>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginScreen;