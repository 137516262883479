import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, ARTICLEENUM, EMAILENUM, HELPCOLUMNS, USERENUM } from "../utils/_constant";
import { ButtonGroup, TableCell, TableRow } from "@mui/material";
import _ from 'lodash';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const HelpListScreen = (props) => {
    const [pageNumber, setpageNumber] = useState(1);
    const [loader, setloader] = useState(false);
    const [dataset, setdataset] = useState([]);
    const [mode, setmode] = useState(0);
    const navigate = useNavigate();

    // useEffect(() => {
    //     handleData();
    // }, []);

    useEffect(() => {
        handleGetData();
    }, [mode]);

    const handleGetData = async () => {

        try {

            if (mode == 0) {

                const getTopics = await _call('topic', 'GET');
                setdataset(getTopics.data);

            } else if (mode == 1) {

                const getSubTopics = await _call('getallsubtopic', 'GET');
                setdataset(getSubTopics.data);

            } else {
                var _data = await _call(`get-all-article`, 'GET');
                _data = _data.data;
                setdataset(_data);
            }


        } catch (err) {
            console.log(err);
        }
    }


    const RenderChildren = () => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        key={row.id}
                        className="cursor-pointer"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left">{row.title || row.name}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell onClick={() => onRemove(row)} align="left" className="text-red-500 font-bold">Delete</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const onRemove = async (item) => {

        const id = item.id;
        var _dataset = [...dataset];
        _dataset = _dataset.filter(x => x.id != id);
        setdataset(_dataset);

        try {
            if (mode == 2) {
                const _remove = await _call(`specific-article/${id}`, 'DELETE');
                return;
            }

            const _re = await _call(`update-topic-subtopic`, 'POST', {
                mode, id
            })

        } catch (err) {
            console.log(err);
        }

    }

    return (
        <Wrapper>

            <div className="row" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-4">
                    <div className="flex flex-row justify-between m-0 mb-2">
                        <h4 style={{ fontWeight: 'bold' }} className="mb-2">Help List</h4>
                        <Button onClick={() => navigate('/help')} variant="contained">Create Help Section</Button>
                    </div>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button onClick={() => setmode(0)} style={{ backgroundColor: mode == 0 ? 'white' : 'teal', color: mode == 0 ? 'teal' : 'white' }}>Topic</Button>
                        <Button onClick={() => setmode(1)} style={{ backgroundColor: mode == 1 ? 'white' : 'teal', color: mode == 1 ? 'teal' : 'white' }}>Sub Topic</Button>
                        <Button onClick={() => setmode(2)} style={{ backgroundColor: mode == 2 ? 'white' : 'teal', color: mode == 2 ? 'teal' : 'white' }}>Article</Button>
                    </ButtonGroup>
                    <div style={{ overflowX: 'scroll', marginTop: 10 }}>
                        {/* {!loader && !_.isEmpty(dataset) &&
                            <TabularComp onIncrease={() => setpageNumber(pageNumber + 1)} onDecrease={() => pageNumber > 0 && setpageNumber(pageNumber - 1)} data={dataset} columnsData={ARTICLEENUM} header="Help List" />
                        } */}
                        <div style={{ overflowX: 'scroll', marginTop: 20 }}>
                            <CuTabularComp renderChildren={RenderChildren} data={dataset} columns={HELPCOLUMNS} />
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper>
    );
}

export default HelpListScreen;