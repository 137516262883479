import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom'
import {
  DisputeScreen, DisputeView, EmailListScreen, EmailScreen,
  LoginScreen,
  HelpListScreen, HelpScreen, Analytics, LeadScreen, OrderScreen, SendEmail, UserScreen, ViewUserScreen, SubscriptionScreen, ServiceCharge, StateTaxScreen
} from '../src/scenes'
import './main.scss'
import 'bootstrap/dist/css/bootstrap.css';

const PrivateRoutes = () => {
  let auth = localStorage.getItem('token');
  return (
    !auth ? <Navigate to="/login" replace /> : <Outlet />
  )
}


const App = (props) => {
  let auth = localStorage.getItem('token');

  return (
    <Router>
      <Routes>
        <Route element={auth ? <Navigate to="/" replace /> : <LoginScreen />} path="/login" />
        <Route element={<PrivateRoutes />}>
          <Route element={<UserScreen />} path="/" />
          <Route element={<ViewUserScreen />} path="/user" />
          <Route element={<LeadScreen />} path="/leads" />
          <Route element={<OrderScreen />} path="/orders" />
          <Route element={<EmailScreen />} path="/email" />
          <Route element={<EmailListScreen />} path="/email-list" />
          <Route element={<HelpScreen />} path="/help" />
          <Route element={<HelpListScreen />} path="/help-list" />
          <Route element={<DisputeScreen />} path="/dispute" />
          <Route element={<DisputeView />} path="/dispute-view" />
          <Route element={<SendEmail />} path="/send-email" />
          <Route element={<Analytics />} path="/analytics" />
          <Route element={<SubscriptionScreen />} path="/subscription" />
          <Route element={<ServiceCharge />} path="/service-charge" />
          <Route element={<StateTaxScreen />} path="/state-tax" />
        </Route>
        <Route path='*' element={<Navigate to='/' />} />

      </Routes>
    </Router>
  );
}

export default App;