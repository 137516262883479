import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, EMAILCOLUMNS, EMAILENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import { Button, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";


const EmailListScreen = (props) => {
    const [pageNumber, setpageNumber] = useState(1);
    const [loader, setloader] = useState(false);
    const [dataset, setdataset] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            setloader(true);
            var _data = await _call(`admin-all-email-list/${ACCESSKEY}`, 'GET');
            // _data = _data.data.map(item => _.omit(item, ['id', 'created_on']));
            setdataset(_data.data);
            setloader(false);

        } catch (err) {
            console.log(err);
        }

    }

    const onEdit = async (item) => {
        navigate('/email', {
            state: {
                data: item
            }
        })
    }

    const RenderChildren = () => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        key={row.id}
                        onClick={() => onEdit(row)}
                        className="cursor-pointer"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.title}
                        </TableCell>
                        <TableCell align="left">{row.notify_key}</TableCell>
                        <TableCell align="left">{row.placeholders}</TableCell>
                        <TableCell align="left">{row.subject}</TableCell>
                        <TableCell align="left">{row.body}</TableCell>

                        <TableCell align="left">{row.notification_text}</TableCell>
                        <TableCell align="left" onClick={() => onEdit(row)}>Edit</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }


    useEffect(() => {
        handleData();
    }, [pageNumber]);

    return (
        <Wrapper>

            <div className="row" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-4">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-2">Email List</h4>
                    <Button onClick={() => navigate('/email', {
                        state: {
                            data: []
                        }
                    })} variant="contained">Create Email</Button>
                    <div style={{ overflowX: 'scroll', marginTop: 0 }}>
                        {/* {!loader && !_.isEmpty(dataset) &&
                    <TabularComp onIncrease={() => setpageNumber(pageNumber + 1)} onDecrease={() => pageNumber > 0 && setpageNumber(pageNumber - 1)} data={dataset} columnsData={EMAILENUM} header="Emails List" />
                } */}

                        <div style={{ overflowX: 'scroll', marginTop: 20 }}>
                            <CuTabularComp renderChildren={RenderChildren} data={dataset} columns={EMAILCOLUMNS} onIncrease={() => console.log()} />
                        </div>
                    </div>
                </div>

            </div>

        </Wrapper>
    );
}

export default EmailListScreen;