import React from 'react';
import { LOGO, LOGONAME, M_LOGO } from '../assets'

const Header = ({ title, paragraph }) => {
    return (
        <div className="row card m-0 shadow-sm h-20 pl-3 pt-2">

            <div className="col-md-12 text-center">
                <div className='bg-black w-10 h-10 inline-flex justify-center items-center mt-2'>
                    <img src={M_LOGO} style={{ width: 60, margin: 'auto' }} />
                </div>
            </div>


        </div>
    );
}
export default Header