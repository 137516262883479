import React, { useState, useRef, useEffect } from "react";
import { Spacer, WrapperProvider } from "../components";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, ButtonGroup } from "@mui/material";
import { _call } from "../utils/api";
import _ from 'lodash';
import { useSingleDataHook } from "../utils/_hooks";
import { _disputeSingleShape } from "../utils/_shape";
import { TocryptHash, decrypt, hookDataReshaper } from "../utils/_function";
import { useLocation } from "react-router-dom";
import NewWindow from 'react-new-window'
import { ACCESSKEY, WebURL, keyId } from "../utils/_constant";
import moment from "moment";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


const CollapsibleElement = ({ title, children }) => {
    const [toggle, settoggle] = useState(true);
    return (
        <>
            <div onClick={() => settoggle(!toggle)} className="bg-gray-700 h-16 m-3 mb-0 shadow-md rounded text-left p-3">
                <h3 className="text-white text-lg">{title}</h3>
            </div>
            {toggle && <div className="bg-white m-3 mt-0 rounded shadow-md text-left p-3">
                {children()}
            </div>}

        </>
    );
}

const _initialDispute = {
    state: false,
    title: '',
    description: ''
}

const _initialEmail = {
    state: false,
    subject: '',
    description: '',
    type: '00'
}

const DisputeView = (props) => {
    const [loader, setloader] = useState(false);
    const [actionState, setactionState] = useState({
        state: false,
        data: [],
        type: 'seller',
    });
    const [disputeEmailState, setdisputeEmailState] = useState(_initialEmail);
    const [disputeNotes, setdisputeNotes] = useState(_initialDispute);
    const [disputeDataset, setdisputeDataset] = useState([]);
    const [disputeStatus, setdisputeStatus] = useState('');
    const subjectRef = useRef();
    const messageRef = useRef();
    const [adminAction, setadminAction] = useState({
        state: false,
        notes: ''
    })
    const location = useLocation();
    const data = location.state.data;

    const _leadDataset = {
        leadId: data.id
    }
    const [loading, response, error, onRefetch] = useSingleDataHook(hookDataReshaper(_disputeSingleShape, _leadDataset));

    useEffect(() => {
        handleData();
        if (!_.isEmpty(response)) {
            setdisputeStatus(response?.message?.dispute?.status);
        }
    }, [response]);

    const handleData = async () => {
        try {
            const _getData = await _call(`admin-disputed/${_leadDataset.leadId}`, 'GET');
            setdisputeDataset(_getData.data);
        } catch (err) {
            console.log(err);
        }
    }
    // const { email, subject, message, name } = req.body;

    const handleOnDisputeEvent = async () => {

        try {

            const _dataset = {
                email: [response?.message?.dispute?.buyer_email, response?.message?.dispute?.seller_email],
                subject: disputeEmailState?.subject,
                message: disputeEmailState?.description,
                name: data.title
            }

            const _sendEmail = await _call('admin-dispute-email', 'POST', _dataset);
            handleAction(disputeEmailState?.type);
            setdisputeEmailState(_initialEmail)

        } catch (err) {
            console.log(err);
        }

    }

    const onNavigate = () => {
        const { categoryName, id, location_id, serviceId } = response.message?.leadInfo;
        const encryptCategory = TocryptHash(keyId, categoryName);

        const locationurl = `${WebURL}leadview/?lead_id=${id}&category=${encryptCategory}&serviceId=${serviceId}&location_id=${location_id}`;

        const newWindow = window.open(locationurl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleAction = async (type) => {

        try {
            setloader(false);

            const _dataset = {
                leadId: data.id,
                action: type,
                notes: adminAction.notes,
                bidId: response?.message?.bid?.id
            };



            const _doAction = await _call(`admin-dispute/${ACCESSKEY}`, 'PATCH', _dataset);
            alert("Completed Successfully");

            setloader(false)
        } catch (err) {
            // console.log(err);
            alert(JSON.stringify(err.response?.data))
            setloader(false)
        }
    }

    const handleSubmit = async () => {
        setactionState({
            ...actionState,
            state: false
        })
        try {
            setloader(false);

            const _dataset = {
                name: actionState?.data?.type == 'seller' ? actionState?.data?.seller_name : actionState?.data?.name,
                email: actionState?.data?.type == 'seller' ? actionState?.data?.seller_email : actionState?.data?.email,
                subject: subjectRef?.current?.value,
                message: messageRef?.current?.value,
            };

            const _doAction = await _call(`admin-email/${ACCESSKEY}`, 'POST', _dataset);
            console.log(_doAction.data);
            alert("Email Sent Successfully")
            setloader(false)
        } catch (err) {
            console.log(err);
            setloader(false)
        }
    }

    const handleSaveNotes = async () => {

        const { title, description } = disputeNotes;

        if (title == '' || description == '') {
            return;
        }

        try {

            const _createNotes = await _call('admin-disputed/0', 'POST', { title, description, lead_id: _leadDataset.leadId, dispute_id: response?.message?.dispute?.id });

            var _disputeNotes = [...disputeDataset];
            _disputeNotes.push({
                title, description, leadId: _leadDataset.leadId, disputeId: response?.message?.dispute?.id,
                id: _createNotes.data.id
            });
            setdisputeDataset(_disputeNotes);
            setdisputeNotes(_initialDispute);

        } catch (err) {
            console.log(err);
        }

    }

    const handleUpdateStatus = async () => {

        try {

            var _status = disputeStatus;
            if (_status == 'PROGRESS') {
                _status = 'ON_HOLD';
                setdisputeStatus('ON_HOLD')
            } else if (_status == 'ON_HOLD') {
                _status = 'ROGRESS';
                setdisputeStatus('PROGRESS')
            }

            const _updateStatus = await _call(`admin-disputed/${response?.message?.dispute?.id}`, 'PUT', {
                disputeId: response?.message?.dispute?.id,
                status: _status
            });



        } catch (err) {
            console.log(err);
        }


    }

    const handleResumeLead = async () => {

        try {
            setloader(true)
            const dataset = {
                status: response?.message?.dispute?.currentStatus,
                disputeId: response?.message?.dispute?.id,
                odId: response?.message?.dispute?.odId,
                odInfoId: response?.message?.dispute?.odInfoId,
                leadId: data.id,
            }

            const _updateStatus = await _call(`dispute`, 'PATCH', { ...dataset });
            setloader(false)
            console.log(_updateStatus.data)
            alert("Updated Successfully")
            setdisputeStatus("Completed")


        } catch (err) {
            setloader(false)
            console.log(err);
        }

    }

    const _renderLeadChildren = () => {

        return (
            <div className="grid grid-cols-7 gap-5">
                <div className="border-r-2"><h3 className="text-lg">${response?.message?.order?.price} <sub className="text-sm text-gray-500">Price Payed</sub></h3></div>
                <div className="border-r-2"><h3 className="text-lg">{response?.message?.dispute?.status} <sub className="text-sm text-gray-500">Status</sub></h3></div>
                <div className="border-r-2 col-span-2" onClick={() => setactionState({
                    state: true,
                    data: {
                        email: response?.message?.dispute?.buyer_email,
                        seller_email: response?.message?.dispute?.seller_email,
                        name: response?.message?.dispute?.buyer_name,
                        seller_name: response?.message?.dispute?.seller_name,
                        type: 'seller'
                    }
                })}><h3 className="text-lg">{response?.message?.dispute?.buyer_name} <sub className="text-sm text-gray-500">Buyer</sub></h3></div>
                <div className="border-r-2 col-span-2"
                    onClick={() => setactionState({
                        state: true,
                        data: {
                            email: response?.message?.dispute?.buyer_email,
                            seller_email: response?.message?.dispute?.seller_email,
                            name: response?.message?.dispute?.buyer_name,
                            seller_name: response?.message?.dispute?.seller_name,
                            type: 'seller'
                        }
                    })}
                ><h3 className="text-lg">{response?.message?.dispute?.seller_name} <sub className="text-sm text-gray-500">Seller</sub></h3></div>
                <div ><h3 className="text-lg">{response?.message?.dispute?.created_by} <sub className="text-sm text-gray-500">Requested</sub></h3></div>
            </div>
        );
    }

    const _renderCoreLeadChildren = () => {

        return (
            <>
                <div className="grid grid-cols-7 gap-5 border rounded p-2 pt-3">
                    <div className="border-r-2"><h3 className="text-lg">{data.title} <sub className="text-sm text-gray-500">Title</sub></h3></div>
                    <div className="border-r-2 col-span-2"><h3 className="text-lg">{data?.categoryName.replace('_', ' ')} - {data?.subCategoryName}</h3></div>
                    <div className="border-r-2 col-span-2"><h3 className="text-lg">{data?.destination_location} <sub className="text-sm text-gray-500">Destination</sub></h3></div>
                    <div className="col-span-2"><h3 className="text-lg">{data?.pickup_location} <sub className="text-sm text-gray-500">Pick up Location</sub></h3></div>

                </div>
                <div className="grid grid-cols-5 mt-3 gap-3 border rounded p-2 pt-3">
                    <div className="border-r-2"><h3 className="text-lg">{moment(data?.created_on).format('DD-MM-YYYY')} <sub className="text-sm text-gray-500">Created Date</sub></h3></div>
                    <div className="border-r-2"><h3 className="text-lg">{moment(response?.message?.order?.order_created_on).format('DD-MM-YYYY')} <sub className="text-sm text-gray-500">Order Created Date</sub></h3></div>
                    <div className="col-span-2"><h3 className="text-lg">{data.description} <sub className="text-sm text-gray-500">Description</sub></h3></div>
                </div>

            </>

        );
    }

    const _renderDisputeInfo = () => <div className="grid grid-cols-7 gap-5 border rounded p-2 pt-3">
        <div className="border-r-2"><h3 className="text-lg">{response?.message?.dispute?.subject} <sub className="text-sm text-gray-500">Subject</sub></h3></div>
        <div className="col-span-6"><h3 className="text-lg">{response?.message?.dispute?.reason}</h3></div>
    </div>

    const _renderHistoryInfo = () => {
        return (
            <>
                {disputeDataset.map(item => {
                    return (
                        <div className="grid grid-cols-7 gap-5 border rounded p-2 pt-3">
                            <div className="border-r-2"><h3 className="text-lg">{item.title} <sub className="text-sm text-gray-500">Subject</sub></h3></div>
                            <div className="col-span-6"><h3 className="text-lg">{item.description}</h3></div>
                        </div>
                    );
                })}
            </>
        );
    }
    return (
        <WrapperProvider
            loading={loading || loader}
            title="Dispute View"
            description="List of all the disputed order"
        >

            {!loading && <>
                <Spacer top={-30} />
                <ButtonGroup variant="contained" aria-label="outlined button group">
                    <Button disabled={disputeStatus == 'Completed'}
                        onClick={() => handleUpdateStatus()}>{disputeStatus == "PROGRESS" ? 'Put in On Hold' : disputeStatus == 'ON_HOLD' ? 'Put in Progress' : 'Completed'}</Button>
                    <Button onClick={() => setdisputeNotes({ ..._initialDispute, state: true })}>History</Button>
                    <Button onClick={() => onNavigate()}>View</Button>
                    <Button onClick={() => handleResumeLead()}>Resume</Button>

                    <Button disabled={disputeStatus == 'Completed'}
                        onClick={() => setdisputeEmailState({ _initialDispute, state: true, type: '00' })}> Mark as Completed</Button>

                    <Button disabled={disputeStatus == 'Completed'}
                        onClick={() => setdisputeEmailState({ _initialDispute, state: true, type: '11' })}> Cancel Order</Button>

                </ButtonGroup>
                <CollapsibleElement title="History" children={_renderHistoryInfo} />
                <CollapsibleElement title="Dispute Information" children={_renderDisputeInfo} />
                <CollapsibleElement title="Order Information" children={_renderLeadChildren} />
                <CollapsibleElement title="Lead Information" children={_renderCoreLeadChildren} />


                {/* <div className="row m-0">
                    <div className="col-md-2">
                        <div className="card shadow-md text-center pt-3 h-20">
                            <h5 className="mb-0 font-bold">${response?.message?.order?.price}</h5>
                            <p className="text-gray-800 font-thin text-xs ">Price payed </p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="card shadow-md text-center pt-3 h-20">
                            <h5 className="mb-0 font-bold">{response?.message?.dispute?.status}</h5>
                            <p className="text-gray-800 font-thin text-xs">Status</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow-md p-3 h-20  w-100">
                            <div className="row m-0">
                                <div className="w-10">
                                    <img className="bg-slate-200 w-10 h-10 rounded-full"
                                        src={response?.message?.dispute?.buyer_img}
                                    />
                                </div>
                                <div className="col">
                                    <p className="text-gray-800 mb-0 font-bold">
                                        {response?.message?.dispute?.buyer_name}
                                    </p>
                                    <p className="text-gray-800 font-thin text-xs">Buyer</p>
                                </div>
                                <button onClick={() => setactionState({
                                    state: true,
                                    data: {
                                        email: response?.message?.dispute?.buyer_email,
                                        seller_email: response?.message?.dispute?.seller_email,
                                        name: response?.message?.dispute?.buyer_name,
                                        seller_name: response?.message?.dispute?.seller_name,
                                        type: 'seller'
                                    }
                                })} className="bg-gray-300 w-14 h-10 text-sm rounded-md">Email</button>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card shadow-md p-3 h-20  w-100">
                            <div className="row m-0">
                                <div className="w-10">
                                    <img className="bg-slate-200 w-10 h-10 rounded-full"
                                        src={response?.message?.dispute?.seller_img}
                                    />
                                </div>
                                <div className="col">
                                    <p className="text-gray-800 mb-0 font-bold">{response?.message?.dispute?.seller_name}</p>
                                    <p className="text-gray-800 font-thin text-xs">Seller</p>
                                </div>
                                <button
                                    onClick={() => setactionState({
                                        state: true,
                                        data: {
                                            email: response?.message?.dispute?.buyer_email,
                                            seller_email: response?.message?.dispute?.seller_email,
                                            name: response?.message?.dispute?.buyer_name,
                                            seller_name: response?.message?.dispute?.seller_name,
                                            type: 'seller'
                                        }
                                    })}
                                    className="bg-gray-300 w-14 h-10 text-sm rounded-md">Email</button>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="card shadow-md text-center pt-3 h-20">
                            <h5 className="mb-0 font-bold">{response?.message?.dispute?.created_by}</h5>
                            <p className="text-gray-800 font-thin text-xs">Requested by</p>
                        </div>
                    </div>
                </div> */}

                <Spacer top={40} />
                {/* <div className="row">
                    <div className="col-md-4">
                        <div className="card shadow-md m-3 p-4">

                            <h5 className="font-bold mb-1">Lead Title</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.title}
                            </p>
                            <h5 className="font-bold mb-1">Lead Description</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.description}
                            </p>
                            <h5 className="font-bold mb-1">Category - Sub Category</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.categoryName.replace('_', ' ')} - {data?.subCategoryName}
                            </p>
                            <h5 className="font-bold mb-1">Destination</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.destination_location}
                            </p>
                            <h5 className="font-bold mb-1">Pick up Location</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.pickup_location}
                            </p>
                            <h5 className="font-bold mb-1">Lead Created Date</h5>
                            <p className="text-gray-800 font-thin">
                                {data?.created_on}
                            </p>
                            <h5 className="font-bold mb-1">Order Created Date</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.order?.order_created_on}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-md m-3 p-4">

                            <h5 className="font-bold mb-1">Bid Subject</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.bid?.subject}
                            </p>
                            <h5 className="font-bold mb-1">Bid Description</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.bid?.description}
                            </p>
                            <h5 className="font-bold mb-1">Bid Price</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.bid?.price}
                            </p>
                            <h5 className="font-bold mb-1">Bid Duration</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.bid?.duration_type}
                            </p>
                            <h5 className="font-bold mb-1">Bid Date</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.bid?.created_on}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-md m-3 p-4">
                            <h5 className="font-bold mb-1">Subject</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.dispute?.subject}
                            </p>
                            <h5 className="font-bold mb-1">Reason</h5>
                            <p className="text-gray-800 font-thin">
                                {response?.message?.dispute?.reason}
                            </p>
                        </div>
                    </div>
                </div> */}

            </>}
            <Modal
                open={actionState.state}
                onClose={() => setactionState({ ...actionState, state: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, background: '#f7f7f7' }}>
                    <h4 id="parent-modal-title" style={{ fontWeight: 'bold' }} className="mb-0">Send Email</h4>
                    <br />
                    <input type="" ref={subjectRef} style={{ height: 50 }} placeholder="Subject" className="form-control" />
                    <br />
                    <textarea className="form-control" rows={10} ref={messageRef}></textarea>
                    <br />
                    <Button
                        onClick={handleSubmit}
                        className="w-100"
                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                    >
                        Send
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={disputeNotes.state}
                onClose={() => setdisputeNotes({ ...disputeNotes, state: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, background: '#f7f7f7' }}>
                    <h4 id="parent-modal-title" style={{ fontWeight: 'bold' }} className="mb-0">Create History</h4>
                    <br />
                    <input type="" value={disputeNotes?.title} onChange={e => setdisputeNotes({ ...disputeNotes, title: e.target.value })} style={{ height: 50 }} placeholder="Subject" className="form-control" />
                    <br />
                    <textarea value={disputeNotes?.description} onChange={e => setdisputeNotes({ ...disputeNotes, description: e.target.value })} className="form-control" rows={10}></textarea>
                    <br />
                    <Button
                        onClick={handleSaveNotes}
                        className="w-100"
                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={disputeEmailState.state}
                onClose={() => setdisputeEmailState({ ...disputeEmailState, state: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, background: '#f7f7f7' }}>
                    <h4 id="parent-modal-title" style={{ fontWeight: 'bold' }} className="mb-0">Send the Resolution</h4>
                    <br />
                    <input type="" value={disputeEmailState?.title} onChange={e => setdisputeEmailState({ ...disputeEmailState, title: e.target.value })} style={{ height: 50 }} placeholder="Subject" className="form-control" />
                    <br />
                    <textarea value={disputeEmailState?.description} onChange={e => setdisputeEmailState({ ...disputeEmailState, description: e.target.value })} className="form-control" rows={10}></textarea>
                    <br />
                    <Button
                        onClick={handleOnDisputeEvent}
                        className="w-100"
                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>
        </WrapperProvider>
    );
}

export default DisputeView;