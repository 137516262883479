import React, { useEffect, useMemo, useState, useRef } from "react";
import { CuTabularComp, Spacer, Wrapper, WrapperProvider } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, SUBSCRIPTIONCOLUMNS, LEADSENUM, LEADSSTATUSEDENUM, USERENUM, ADMIN_INSURANCE, ADMIN_SERVICE_CHARGE, ADMIN_TAX_STATE_CHARGE } from "../utils/_constant";
import _ from 'lodash';
import moment from 'moment';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, ButtonGroup } from "@mui/material";

import { useGetHook, useSingleDataHook } from "../utils/_hooks";
import { TableCell, TableRow } from "@mui/material";
import { _insuranceSchema, _serviceChargeSchema, _serviceChargeTaxSchema, _subscriptionShape } from "../utils/_shape";
import { useNavigate } from "react-router-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const _initial = {
    state: false,
    percentage: '',
    state_name: '',
    data: []
}


const StateTaxScreen = (props) => {
    const [loading, response, error, onRefetch] = useSingleDataHook(_serviceChargeTaxSchema);
    const [dataset, setdataset] = useState(response);
    const [validationError, setvalidationError] = useState({
        state: false,
        message: '',
        type: 'success'
    });
    const [information, setinformation] = useState(_initial)

    useEffect(() => {
        setdataset(response);
    }, [response]);


    useEffect(() => {
        if (validationError.state) {
            setTimeout(() => {
                setvalidationError({ ...validationError, state: false });
            }, 3000);
        }
    }, [validationError.state]);

    const handleOperation = async () => {
        const { state_name, percentage } = information;

        if (percentage == '' || state_name == '') {
            setvalidationError({
                state: true,
                message: 'Please complete the fields',
                type: 'error'
            });
            return;
        }

        try {

            if (_.isEmpty(information?.data)) {

                const _addInfo = await _call('admin-service-tax-charge', 'POST', {
                    percentage,
                    state_name
                });

                var _dataset = [...dataset];

                _dataset.push({
                    percentage,
                    state_name,
                    id: _addInfo.data.id
                });

                setdataset(_dataset);
                // handleData();
                setvalidationError({
                    state: true,
                    message: 'Created successfully',
                    type: 'success'
                });

                setTimeout(() => {
                    setinformation(_initial)
                }, 2000);


            } else {

                const _updatedInfo = await _call(`admin-service-tax-charge/${information?.data?.id}`, 'PUT', {
                    percentage,
                    state_name
                });

                var _dataset = [...dataset];
                const Index = _dataset.findIndex(x => x.id == information?.data?.id);
                _dataset[Index] = {
                    ..._dataset,
                    percentage,
                    state_name
                }
                setdataset(_dataset);

                setvalidationError({
                    state: true,
                    message: 'Updated successfully',
                    type: 'success'
                });

                setTimeout(() => {
                    setinformation(_initial)
                }, 2000);


            }

        } catch (err) {
            console.log(err)
            setvalidationError({
                state: true,
                message: 'Some problem occured',
                type: 'error'
            });
        }

    }

    const onEdit = async (item) => {
        const { percentage, state_name } = item;
        setinformation({
            state: true,
            percentage,
            state_name,
            data: item
        })
    }

    const onDelete = async (item) => {
        try {

            const _deleteData = _call(`admin-service-tax-charge/${item.id}`, 'DELETE');
            var _dataset = [...dataset];
            _dataset = _dataset.filter(x => x.id != item.id);
            setdataset([..._dataset]);


        } catch (err) {
            console.log(err);
            setvalidationError({
                state: true,
                message: 'Some problem occured',
                type: 'error'
            });
        }

    }


    const RenderChildren = (onEdit, onDelete) => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left">{row.state_name}</TableCell>
                        <TableCell align="left">{row.percentage}</TableCell>
                        <TableCell align="left" onClick={() => onEdit(row)}>Edit</TableCell>
                        <TableCell align="left" onClick={() => onDelete(row)}>Delete</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const navigate = useNavigate();

    return (
        <WrapperProvider
            loading={loading}

        >
            <div className="row text-left" style={{ marginTop: -20, overflowX: "hidden" }}>
                <div className="col-md-12 ml-4">
                    <div className="row">
                        <div className="col-md-11">
                            <h4 style={{ fontWeight: 'bold' }} className="mb-2">State Charge Section</h4>
                            <p className="mb-3">List of State Tax</p>
                            <ButtonGroup variant="contained" aria-label="outlined primary button group" className="mb-3">
                                <Button variant={"contained"} onClick={() => navigate('/subscription')}>Insurance</Button>
                                <Button variant={"contained"} onClick={() => navigate('/service-charge')}>Service Charge</Button>
                                <Button variant={"outlined"} onClick={() => navigate('/state-tax')}>State Charge</Button>
                            </ButtonGroup>
                        </div>
                        <div className="col-md-1 text-right">
                            <img onClick={() => setinformation({ ...information, state: true })} src={require('../assets/icons/plus.png')} style={{ width: 40, height: 40 }} />
                        </div>
                    </div>

                    <div style={{ overflowX: 'scroll', marginTop: 25 }}>
                        <CuTabularComp renderChildren={RenderChildren} onEdit={onEdit} onDelete={onDelete} next={false}
                            data={dataset} columns={ADMIN_TAX_STATE_CHARGE} />
                    </div>
                </div>

            </div>
            <Modal
                open={information.state}
                onClose={() => setinformation({ ...information, state: false })}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400, background: '#f7f7f7' }}>
                    <h4 id="parent-modal-title" style={{ fontWeight: 'bold' }} className="mb-0">Set Service Charge Information</h4>
                    <br />
                    <input type="text" value={information?.state_name} onChange={e => setinformation({ ...information, state_name: e.target.value })} style={{ height: 50 }} placeholder="State Name" className="form-control" />
                    <br />
                    <input type="number" value={information?.percentage} onChange={e => setinformation({ ...information, percentage: e.target.value })} style={{ height: 50 }} placeholder="Percentage" className="form-control" />
                    <br />
                    {validationError.state && <h4 className={`text-sm ${validationError?.type == 'error' ? "text-red-400" : "text-green-400"} text-center`}>{validationError?.message}</h4>}
                    <Button
                        onClick={handleOperation}
                        className="w-100"
                        style={{ backgroundColor: '#444444', color: "#fff", fontWeight: 'bold', height: 60 }}
                    >
                        Submit
                    </Button>
                </Box>
            </Modal>

        </WrapperProvider>
    );
}

export default StateTaxScreen;