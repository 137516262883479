import React, { useEffect, useRef, useState } from "react";
import { Spacer, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, EMAILTOPIC, LEADSENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';
import { Button, ButtonGroup, FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";


const SendEmail = (props) => {
    const [selectedEmail, setselectedEmail] = useState([]);
    const [subject, setsubject] = useState('');
    const [notification_text, setnotification_text] = useState('');
    const editorRef = useRef(null);


    const handleSubmit = async () => {
        if (subject == '' || notification_text == '' || _.isEmpty(selectedEmail)) {
            alert('Please complete all required fields');
            return
        }

        const _shape = {
            body: editorRef.current.getContent(),
            subject,
            notification_text,
            placeholders: selectedEmail.placeholders.toString(),
            title: selectedEmail.label,
            notify_key: selectedEmail.value,
            id: 0
        }

        console.log(_shape)
        try {

            const _data = await _call(`admin-all-email-list/${ACCESSKEY}`, 'POST', _shape);
            alert('Created Successfully');
            console.log(_data);


        } catch (err) {
            console.log(err);
        }

    }

    return (
        <Wrapper>
            <h1 className="bold mb-0 text-4xl ml-2 mt-1">Send Email
            </h1>
            <p className="bold mb-0 ml-2 mt-1">You can find the list of all emails here.</p>

            <div className="container mt-5">
                <div className="col-md-8 offset-md-2">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Email</InputLabel>
                        <TextField id="outlined-basic"
                            value={subject}
                            onChange={e => setsubject(e.target.value)}
                            label="Email" variant="outlined" />
                    </FormControl>
                    {!_.isEmpty(selectedEmail) &&
                        <>
                            <Spacer top={20} />
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                {selectedEmail.placeholders.map(item => <Button style={{ textTransform: 'auto' }}>{item}</Button>)}
                            </ButtonGroup>
                        </>
                    }
                    <Spacer top={20} />
                    <FormControl fullWidth>
                        <TextField id="outlined-basic"
                            value={subject}
                            onChange={e => setsubject(e.target.value)}
                            label="Subject" variant="outlined" />
                    </FormControl>
                    <Spacer top={20} />

                    <FormControl fullWidth>
                        <Editor
                            onInit={(evt, editor) => {
                                editorRef.current = editor
                            }}
                            apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                            initialValue=""
                            init={{
                                height: 400,
                                menubar: true,
                                image_title: true,
                                file_picker_types: 'file image media',
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste image code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | help",
                                content_style:
                                    `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                            }}
                        />
                    </FormControl>
                    <Spacer top={30} />
                    <Button onClick={handleSubmit} variant="contained" fullWidth className="p-3">Save</Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default SendEmail;