import React, { useEffect, useRef, useState } from "react";
import { Spacer, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, EMAILTOPIC, LEADSENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Editor } from '@tinymce/tinymce-react';
import { Button, ButtonGroup, FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";


const EmailScreen = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state.data || [];
    const [selectedEmail, setselectedEmail] = useState([]);
    const [subject, setsubject] = useState('');
    const [notification_text, setnotification_text] = useState('');
    const editorRef = useRef(null);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            setsubject(data.subject);
            setnotification_text(data.notification_text);
            setselectedEmail(EMAILTOPIC.find(x => x.value == data.notify_key))
        }
    }, [data]);

    const handleSubmit = async () => {
        if (subject == '' || notification_text == '' || _.isEmpty(selectedEmail)) {
            alert('Please complete all required fields');
            return
        }

        const _shape = {
            body: editorRef.current.getContent(),
            subject,
            notification_text,
            placeholders: selectedEmail.placeholders.toString(),
            title: selectedEmail.label,
            notify_key: selectedEmail.value,
            id: _.isEmpty(data) ? 0 : data.id
        }

        try {

            const _data = await _call(`admin-all-email-list/${ACCESSKEY}`, 'POST', _shape);
            alert("Saved Successfully");

            console.log(_data);


        } catch (err) {
            console.log(err);
            alert("Already Exist");
        }

    }

    return (
        <Wrapper
            title="Create Email"
            description="You can find the list of all emails here."
        >

            <div className="container mt-2">
                <div className="col-md-10 offset-md-1 bg-white p-4">
                    <h5 className="mb-4">Create / Edit Templates</h5>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Email Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedEmail}
                            label="Email Type"
                            onChange={e => setselectedEmail(e.target.value)}
                        >
                            {EMAILTOPIC.map(item => <MenuItem value={item}>{item.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {!_.isEmpty(selectedEmail) &&
                        <>
                            <Spacer top={20} />
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                {selectedEmail.placeholders.map(item => <Button style={{ textTransform: 'auto' }}>{item}</Button>)}
                            </ButtonGroup>
                        </>
                    }
                    <Spacer top={20} />
                    <FormControl fullWidth>
                        <TextField id="outlined-basic"
                            value={subject}
                            onChange={e => setsubject(e.target.value)}
                            label="Subject" variant="outlined" />
                    </FormControl>
                    <Spacer top={20} />
                    <FormControl fullWidth>
                        <TextField id="outlined-basic"
                            value={notification_text}
                            onChange={e => setnotification_text(e.target.value)}
                            label="Notification Body" variant="outlined" />
                    </FormControl>
                    <Spacer top={20} />
                    <FormControl fullWidth>
                        <Editor
                            onInit={(evt, editor) => {
                                editorRef.current = editor
                            }}
                            apiKey="lkzzoj151a0qmcchiz157phemuiwfkgm096qkk182krjxrtu"
                            initialValue={!_.isEmpty(data) ? data?.body : ""}
                            init={{
                                height: 400,
                                menubar: true,
                                image_title: true,
                                file_picker_types: 'file image media',
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste image code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | help",
                                content_style:
                                    `body { font-family: Roboto,Arial,sans-serif; font-size:14px }`,
                            }}
                        />
                    </FormControl>
                    <Spacer top={30} />
                    <Button onClick={handleSubmit} variant="contained" fullWidth className="p-3">Save</Button>
                </div>
            </div>
            {/* <ToastContainer /> */}
        </Wrapper>
    );
}

export default EmailScreen;