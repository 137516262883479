import React from 'react'
import styled from 'styled-components'
import { useTable, useSortBy, useFilters, useColumnOrder } from 'react-table'
import { AnimatePresence, motion } from 'framer-motion'
import { matchSorter } from 'match-sorter'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #efefef;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #efefef;
      border-right: 1px solid #efefef;
      background-color:#ffffff;

      :last-child {
        border-right: 0;
      }
    }

    th{
        background-color:#444444;
        color:#ffffff;
        padding:20px;
        font-weight:bold;
    }
  }
`

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            value={filterValue || ''}
            className="w-100 border-1 border-r-2 p-2 mt-2 mb-2 shadow-sm border-gray-500 text-black"
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search here`}
        />
    )
}



function Table({ columns, data, onPress }) {
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
        },
        useColumnOrder,
        useFilters,
        useSortBy
    )

    const spring = React.useMemo(
        () => ({
            type: 'spring',
            damping: 50,
            stiffness: 100,
        }),
        []
    )

    // const randomizeColumns = () => {
    //     setColumnOrder(shuffle(visibleColumns.map(d => d.id)))
    // }

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => {
                                return column.hideHeader === false ? null : (
                                    (
                                        <motion.th
                                            {...column.getHeaderProps({
                                                layoutTransition: spring,
                                                style: {
                                                    minWidth: column.minWidth,
                                                },
                                            })}
                                            className="font-medium text-gray-100"
                                        >
                                            <div {...column.getSortByToggleProps()}

                                            >
                                                {column.render('Header')}

                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </div>
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </motion.th>
                                    )
                                );
                            }
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    <AnimatePresence>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <motion.tr
                                    {...row.getRowProps({
                                        layoutTransition: spring,
                                        exit: { opacity: 0, maxHeight: 0 },
                                    })}
                                    onClick={() => onPress(row)}
                                >
                                    {row.cells.map((cell, i) => {
                                        return (
                                            <motion.td
                                                {...cell.getCellProps({
                                                    layoutTransition: spring,
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </motion.td>
                                        )
                                    })}
                                </motion.tr>
                            )
                        })}
                    </AnimatePresence>
                </tbody>
            </table>
            {/* <pre>
                <code>{JSON.stringify(state, null, 2)}</code>
            </pre> */}
        </>
    )
}

const TabularComp = ({ data, columnsData, header, onIncrease, onDecrease, onPress }) => {


    const handlePrepareColumns = () => {
        var _columns = Object.keys(data[0]).map(key => {
            return {
                Header: columnsData[key],
                accessor: key,
                minWidth: 300,
                filter: 'fuzzyText',
            }
        });
        return _columns;
    }


    const columns = React.useMemo(
        () => [
            {
                Header: header,
                hideHeader: false,
                columns: handlePrepareColumns(),
            },
        ],
        []
    )


    return (

        <div>
            <div className="mt-5">

                <Styles>
                    <Table columns={columns} data={data} onPress={onPress} />
                </Styles>
                <div className="flex-1 w-100 mt-5 text-right pr-3">
                    {/* <button onClick={onDecrease} className='bg-black w-32 rounded-full text-white p-2'>Back</button> */}
                    <button onClick={onIncrease} className='bg-blue-500 w-32 rounded-full text-white p-2 ml-3'>Next</button>
                </div>
            </div>
        </div>
    );
}

export default TabularComp;