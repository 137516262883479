import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { _call } from "./api";

const useGetHook = (request) => {
    const { url, data, method } = request;
    const [loading, setloading] = useState(false);
    const [pageSize, setpageSize] = useState(1);
    const [response, setresponse] = useState([]);
    const [error, seterror] = useState([]);

    useEffect(() => {
        const handleData = async () => handleProcess(data);
        handleData();
    }, []);

    const handleProcess = async (_data) => {
        try {
            // setloading(true);
            const _request = await _call(url, method, _data);
            var _omitData = [...response, ..._request.data];
            setresponse(_omitData);
            setloading(false);
        } catch (err) {
            setloading(false);
            seterror(err);
        }
    }

    const onRefetch = async (page) => {
        var _dataset = data;
        _dataset['pageSize'] = pageSize + 1;
        setpageSize(pageSize + 1);
        handleProcess(_dataset);
    }

    return [loading, response, error, onRefetch];
};

const useSingleDataHook = (request) => {
    const { url, data, method } = request;
    const [loading, setloading] = useState(false);
    const [response, setresponse] = useState([]);
    const [error, seterror] = useState([]);

    useEffect(() => {
        const handleData = async () => handleProcess(data);
        handleData();
    }, []);

    const handleProcess = async () => {
        try {
            // setloading(true);
            const _request = await _call(url, method, data);
            setresponse(_request.data);
            setloading(false);
        } catch (err) {
            setloading(false);
            seterror(err);
        }
    }

    const onRefetch = async () => {
        handleProcess();
    }

    return [loading, response, error, onRefetch];
}

export {
    useGetHook,
    useSingleDataHook
}