import React from "react";
import moment from "moment";

const handleExpiryDate = (date) => {
    const now = moment.utc();
    var end = moment(date);
    var days = end.diff(now, "days");
    return days > 1 ? moment(date).format('DD-MM-YYYY') : days <= 0 ? "Expired" : 'About to Expire';
};

const hookDataReshaper = (params, data) => {
    return { ...params, data }
}


export const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};

const TocryptHash = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};


export {
    TocryptHash,
    handleExpiryDate,
    hookDataReshaper,
}