import axios from 'axios';
import _, { isEmpty } from 'lodash';

// axios.defaults.baseURL = 'http://localhost:4040/api/';
// axios.defaults.baseURL = "https://bijlink.herokuapp.com/api/";
// axios.defaults.baseURL = "https://bdconnct.peerschain.com/api/";
// axios.defaults.baseURL = "https://backend.bijlink.com/api/"
axios.defaults.baseURL = "https://bijlinkapi.herokuapp.com/api/";

const getToken = async () => {
    return await localStorage.getItem('token');
}

const _call = (url, method, data = {}, headers = null) => new Promise(async (resolve, reject) => {

    const token = await getToken();
    var headerObj = {
        Authorization: 'Token ' + token,
    }
    if (headers) {
        headerObj = headers;
    }

    var params = {
        method,
        url,
        headers: headerObj
    };

    if (!isEmpty(data)) {
        params.data = data;
    }

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});

const _multipartCall = (url, method, data) => new Promise(async (resolve, reject) => {

    const token = await getToken();

    const fd = new FormData();

    data.map((item, index) => {
        fd.append(item.key, item.value);
    });

    var params = {
        method,
        url,
        headers: {
            Authorization: 'Token ' + token,
            "Content-Type": 'multipart/form-data'
        },
        data: fd
    }

    console.log(fd)

    axios(params).then(res => resolve(res)).catch(err => reject(err));
});

export { _call, _multipartCall };