import React from "react";
import Sidebar from "./_sidebar";
import Header from "./_header";
import LoadingView from "./_loadingview";

const WrapperProvider = (props) => {
    const { children, loading, title, description } = props;
    return (
        <div className="container-fluid m-0 p-0" style={{ background: '#f7f7f7' }}>
            {/* <Header title={title} paragraph={description} /> */}
            <div className="row">
                <div style={{ width: 100 }}>
                    <Sidebar />
                </div>
                <div className="row m-0" style={{ width: 'calc(100% - 100px)' }}>
                    <div className="col-md-12 text-center pt-5">
                        <LoadingView loading={loading} />
                        {!loading && children}
                    </div>
                </div>

            </div>
        </div>
    );
}
export default WrapperProvider;