import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { _call } from "../utils/api";
import { ACCESSKEY, USERCOLUMNS, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";


const UserScreen = (props) => {
    const [pageNumber, setpageNumber] = useState(1);
    const [loader, setloader] = useState(false);
    const [__dataset, set__dataset] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [mode, setmode] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {

        try {
            setloader(true);
            const _filter = {
                pageSize: 100,
                pageNumber: pageNumber
            }
            const _data = await _call(`admin-all-users/${ACCESSKEY}`, 'POST', _filter);
            setdataset([...dataset, ..._data.data]);
            set__dataset([...dataset, ..._data.data])
            setloader(false);

        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        handleData();
    }, [pageNumber]);

    const onViewUser = (item) => {
        console.log(item)
        navigate('/user', {
            state: {
                data: item
            }
        })
    }

    const RenderChildren = () => {
        const now = moment.utc();
        return (
            <>
                {dataset.map((row) => {
                    var end = moment(row.cro);
                    var days = now.diff(end, "days");
                    return (
                        <TableRow
                            onClick={() => onViewUser(row)}
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            className={days <= 10 ? "bg-blue-200" : "bg-white"}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.phone}</TableCell>
                            <TableCell align="left">{row.dob}</TableCell>
                            <TableCell align="left">{row.address}</TableCell>
                            <TableCell align="left">{row.user_type}</TableCell>
                            <TableCell align="left">{row.created_on}</TableCell>
                        </TableRow>
                    )
                })}
            </>
        );
    }

    useEffect(() => {

        if (mode == 3) {
            setdataset(_.orderBy(__dataset?.filter(x => x.status == 'REJECTED' || x.status.toLowerCase() == 'rejected'), 'id', 'desc'))
            return;
        }

        if (mode == 1) {
            setdataset(_.orderBy(__dataset?.filter(x => x.status?.toLowerCase() == 'approved' || x.status == 'A' || x.status == 'Accepted' || x.status == 'Active' || x.status?.toLowerCase() == 'active'), 'id', 'desc'))
            return;
        }

        if (mode == 0) {
            setdataset(_.orderBy(__dataset, 'id', 'desc'))
            return;
        }

        if (mode == 2) {
            setdataset(_.orderBy(__dataset?.filter(x => x.status == 'P' || x.status == 'Pending'), 'id', 'desc'))
            return;
        }


    }, [mode])

    return (
        <Wrapper
            title="Users"
            description="You can find the list of all users here."
        >

            <div className="row" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-3">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-0">Users List</h4>
                    <p className="mt-0 mb-3">List of all users. Tap on do necessary actions</p>
                    <ButtonGroup variant="contained" aria-label="outlined primary button group" className="mb-3">
                        <Button onClick={() => setmode(0)} variant={mode == 0 ? "contained" : "outlined"}>All</Button>
                        <Button onClick={() => setmode(1)} variant={mode == 1 ? "contained" : "outlined"}>Active</Button>
                        <Button onClick={() => setmode(2)} variant={mode == 2 ? "contained" : "outlined"} >Pending</Button>
                        <Button onClick={() => setmode(3)} variant={mode == 3 ? "contained" : "outlined"} >Rejected</Button>
                    </ButtonGroup>
                    {!loader && !_.isEmpty(dataset) &&
                        <CuTabularComp renderChildren={RenderChildren} data={dataset} columns={USERCOLUMNS} />
                    }
                </div>

            </div>
        </Wrapper>
    );
}

export default UserScreen;