import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingView = ({ loading = false }) => {
    if (!loading) return <></>

    return (
        <div className='text-center'>
            <CircularProgress color="inherit" />
            <h5 className='flexfull mt-2'>We are submitting your request.</h5>
            <h6 className='flexfull mt-2'>Please wait...</h6>
        </div>
    );
}

export default LoadingView;