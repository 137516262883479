const ACCESSKEY = 'b77cbdccb06427295770b90e46f888e6b2e3f51d4d50591d823a5805e8447d81';
const imageURL = 'https://bijlink-s3.s3-us-east-2.amazonaws.com/';

// const WebURL = 'http://localhost:3000/';
const WebURL = 'https://www.bijlink.com/';

const USERENUM = {
    name: 'Name',
    email: 'Email',
    phone: 'Phone Number',
    dob: 'Date of Birth',
    address: 'Address',
    user_type: 'User Type',
    created_on: 'Created On'
};

const keyId = 'peerschain*1092210__*&';

const USERKEYS = ["name", "email", "phone", "dob", "user_type", "address", "created_on"];
const USERCOLUMNS = ["Name", "Email", "Phone Number", "Date of Birth", "Address", "User Type", "Created On"];
const HELPCOLUMNS = ["Title", "Description", "Action"]
const LEADCOLUMNS = ["Category", "Order Number", "Lead Title", "Price ($)", "Created On", "Pick up Location", "Destination Location"];
const EMAILCOLUMNS = ["Title", "Key", "Placeholders", "Subject", "Body", "Notification", "Edit"];
const ADMIN_INSURANCE = ["Title", "Range 1", "Range 2", "Amount", "Edit", "Delete"];
const ADMIN_TAX_STATE_CHARGE = ["State Name", "Percentage", "Edit", "Delete"];
const ADMIN_SERVICE_CHARGE = ["Title", "Percentage", "User Type", "Edit", "Delete"];

const LEADSENUM = {
    order_number: 'Order Number',
    categoryName: 'Category',
    subCategoryName: 'Sub Category',
    status: 'Status',
    title: 'Lead Title',
    description: 'Lead Description',
    price: 'Price ($)',
    created_on: 'Created On',
    pickup_location: 'Pick up Location',
    destination_location: 'Destination Location'
}

const LEADSLISTENUM = ["Order Number", "Status", "Title", "Price", "Created On", "Pick up Location", "Destination Location"];
const ORDERSLISTENUM = ["Order Number", "Stop Payment", "Status", "Buyer", "Seller", "Title", "Price", , "Is Disputed", "Created On", "Pick up Location", "Destination Location"];

const LEADSSTATUSEDENUM = {
    order_number: 'Order Number',
    categoryName: 'Category',
    subCategoryName: 'Sub Category',
    price: 'Price ($)',
    created_on: 'Created On',
    pickup_location: 'Pick up Location',
    destination_location: 'Destination Location',
    status: 'Status',
    title: 'Lead Title',
    description: 'Lead Description',
    subscription_type: 'Subscription',
    buyer_name: 'Buyer',
    seller_name: 'Seller',
};

const EMAILENUM = {
    title: 'Title',
    notify_key: 'Key',
    placeholders: 'Placeholders',
    subject: 'Subject',
    body: 'Body',
    notification_text: 'Notification',
    created_on: 'Created On'
}

const ARTICLEENUM = {
    extra_info: 'Extra Information',
    description: 'Description',
    title: 'Title'
}

const EMAILTOPIC = [
    { id: -1, label: 'Dispute User Account', value: 'DISPUTE_USER_ACCOUNT', placeholders: ['Name', 'Email', 'Title'] },
    { id: 0, label: 'User Create Account', value: 'USER_CREATE_ACCOUNT', placeholders: ['Name', 'Email'] },
    { id: 1, label: 'User Change Password', value: 'USER_CHANGE_PASSWORD', placeholders: ['Name', 'Email'] },
    { id: 1, label: 'User Change Email', value: 'USER_CHANGE_EMAIL', placeholders: ['Name', 'Email'] },
    { id: 1, label: 'User Change Phone Number', value: 'USER_CHANGE_PHONE', placeholders: ['Name', 'Email', 'Phone'] },

    { id: 2, label: 'Create Lead', value: 'CREATE_LEAD', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },
    { id: 2, label: 'Buyer Feedback', value: 'BUYER_FEEDBACK', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },
    { id: 2, label: 'Seller Feedback', value: 'SELLER_FEEDBACK', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },
    { id: 3, label: 'Give Tip', value: 'GIVE_TIP', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },

    { id: 3, label: 'Booking Confirmation', value: 'BOOKING_CONFIRMATION', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },

    { id: 4, label: 'Received Bidding for leads', value: 'BID_RECEIVED', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Bid Price', 'Bid Description', 'Bid Title'] },
    { id: 5, label: 'Contractors complete Job', value: 'CONTRACTOR_COMPLETE_JOB', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory'] },

    { id: 6, label: 'Customer Complete Job', value: 'JOB_COMPLETED', placeholders: ['Title', 'Destination', 'Pickup', 'Category', 'SubCategory'] },

    { id: 7, label: 'User Received Question', value: 'RECEIVED_QUESTION', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Question'] },
    { id: 8, label: 'User Received Answer', value: 'RECEIVED_ANSWER', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Answer'] },

    { id: 9, label: 'Accept Quote', value: 'QUOTE_ACCEPT', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Bid Price', 'Bid Description', 'Bid Title'] },
    { id: 10, label: 'Decline Quote', value: 'QUOTE_DECLINE', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Bid Price', 'Bid Description', 'Bid Title'] },

    { id: 11, label: 'User Change (Email, Phone Number, Address, Name )', value: 'META_CHANGE', placeholders: ['Name', 'Email', 'Type'] },
    { id: 12, label: 'User Update Account', value: 'ACCOUNT_UPDATE', placeholders: ['Name', 'Email', 'Type'] },

    { id: 13, label: 'Lead Expiry', value: 'LEAD_EXPIRY', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },
    { id: 14, label: 'Lead Expired', value: 'LEAD_EXPIRED', placeholders: ['Name', 'Email', 'Title', 'Destination', 'Pickup'] },

    { id: 15, label: 'Pick Up Confirmation', value: 'PICK_UP_CONFIRMATION', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory'] },
    { id: 16, label: 'Drop Off Confirmation', value: 'DROP_OFF_CONFIRMATION', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory'] },
    { id: 23, label: 'Ask for Confirmation', value: 'ASK_FOR_CONFIRMATION', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory'] },
    { id: 17, label: 'Watch Lead Expiry', value: 'WATCH_LEAD_EXPIRY', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Question'] },
    { id: 18, label: 'Cancel Bid Offer By Customer', value: 'CANCEL_BID_CUSTOMER', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Question'] },
    { id: 18, label: 'Cancel Pending Offer By Customer / Contractor', value: 'CANCEL_PENDING_OFFER', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Question'] },
    { id: 19, label: 'Cancel Bid Offer By Contractor', value: 'CANCEL_BID_CONTRACTOR', placeholders: ['BuyerName', 'BuyerEmail', 'SellerName', 'SellerEmail', 'Title', 'Destination', 'Pickup', 'Category', 'SubCategory', 'Question'] },

    { id: 20, label: 'Account Acceptance Email', value: 'ACCOUNT_ACCEPTANCE', placeholders: ['Name', 'Email'] },
    { id: 21, label: 'Skill Acceptance Email', value: 'SKILL_ACCEPTANCE', placeholders: ['Name', 'Email'] },
    { id: 22, label: 'Skill Rejection Email', value: 'SKILL_REJECTION', placeholders: ['Name', 'Email'] },
]


const firebaseConfig = {
    apiKey: "AIzaSyBxvjpkzsMIXzF9NL4dG8f_Tzs0_FiLgjk",
    authDomain: "etcetramart-phase-2.firebaseapp.com",
    projectId: "etcetramart-phase-2",
    storageBucket: "etcetramart-phase-2.appspot.com",
    messagingSenderId: "51875565685",
    appId: "1:51875565685:web:88a029da967eeb76d6283c",
    measurementId: "G-2E2DDK6JME"
}


export {
    USERENUM,
    ACCESSKEY,
    LEADSENUM,
    LEADSSTATUSEDENUM,
    EMAILTOPIC,
    EMAILENUM,
    ARTICLEENUM,
    USERCOLUMNS,
    USERKEYS,
    imageURL,
    LEADCOLUMNS,
    firebaseConfig,
    ADMIN_INSURANCE,
    ADMIN_SERVICE_CHARGE,
    ADMIN_TAX_STATE_CHARGE,
    WebURL,
    LEADSLISTENUM,
    ORDERSLISTENUM,
    HELPCOLUMNS,
    EMAILCOLUMNS,
    keyId
}