import { ACCESSKEY } from "./_constant";

const disputeShape = {
    url: `admin-all-leads/${ACCESSKEY}`,
    method: 'GET'
};

const _disputeSingleShape = {
    url: `admin-dispute/${ACCESSKEY}`,
    method: 'POST',
    data: {},
}

const _analyticsSingleShape = {
    url: `admin-analytics/${ACCESSKEY}`,
    method: 'POST',
    data: {
        type: ''
    },
}

const _subscriptionShape = {
    url: `admin-subscription/${ACCESSKEY}`,
    method: 'GET',
}

const _insuranceSchema = {
    url: 'admin-insurance',
    method: 'GET'
}

const _serviceChargeSchema = {
    url: 'admin-service-charge',
    method: 'GET'
}

const _serviceChargeTaxSchema = {
    url: 'admin-service-tax-charge',
    method: 'GET'
}

export {
    disputeShape,
    _disputeSingleShape,
    _analyticsSingleShape,
    _subscriptionShape,
    _insuranceSchema,
    _serviceChargeSchema,
    _serviceChargeTaxSchema
}