import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Spacer, Wrapper, WrapperProvider, ChartView } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, LEADCOLUMNS, LEADSENUM, LEADSSTATUSEDENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from 'moment';
import { useGetHook, useSingleDataHook } from "../utils/_hooks";
import { TableCell, TableRow } from "@mui/material";
import { _analyticsSingleShape } from "../utils/_shape";
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";

const CollapsibleElement = ({ title, children }) => {
    const [toggle, settoggle] = useState(true);
    return (
        <>
            <div onClick={() => settoggle(!toggle)} className="bg-gray-700 h-16 m-3 mb-0 shadow-md rounded text-left p-3">
                <h3 className="text-white text-lg">{title}</h3>
            </div>
            {toggle && <div className="bg-white m-3 mt-0 rounded shadow-md text-left p-3">
                {children()}
            </div>}

        </>
    );
}

const Analytics = (props) => {
    const navigate = useNavigate();
    const [loading, setloading] = useState([]);
    const [response, setresponse] = useState([]);
    const [leadShape, setleadShape] = useState([]);
    const [userShape, setuserShape] = useState([]);
    const [categoryShape, setcategoryShape] = useState([]);
    const [destinationCityShape, setdestinationCityShape] = useState([]);
    const [destinationStateShape, setdestinationStateShape] = useState([]);
    const [pickupCityShape, setpickupCityShape] = useState([]);
    const [pickupStateShape, setpickupStateShape] = useState([]);
    const [dataset, setdataset] = useState([]);

    useEffect(() => {
        if (response) {
            handleReshaping();
        }
    }, [response]);

    const handleReshaping = async () => {

        try {
            // setloading(true);
            const _response = await _call(`admin-analytics/${ACCESSKEY}`, 'POST', { type: '' });
            setdataset(_response.data);
            const { lead, user, category } = _response.data

            var _leadShape = [], _userShape = [], _categoryShape = [];
            var _destinationCityShape = [], _destinationStateShape = [];
            var _pickupCityShape = [], _pickupStateShape = [];

            console.log(response)

            if (!_.isEmpty(lead)) {
                Object.keys(lead).map((key, value) => {
                    var _shape = [key, value];
                    _leadShape.push(_shape);
                });
                setleadShape(_leadShape);
            }

            if (!_.isEmpty(user)) {
                _userShape.push([
                    "Seller", user?.seller
                ]);
                _userShape.push([
                    "Buyer", user?.buyer
                ]);
                setuserShape(_userShape)
            }

            if (!_.isEmpty(category)) {
                Object.keys(category?.total).map((key, value) => {
                    var _shape = [key, value];
                    _categoryShape.push(_shape);
                });
                console.log(_categoryShape)
                setcategoryShape(_categoryShape);

                Object.keys(category.destination.city).map((key, value) => {
                    var _shape = [key, value];
                    _destinationCityShape.push(_shape);
                });
                setdestinationCityShape(_destinationCityShape);

                Object.keys(category.destination.state).map((key, value) => {
                    var _shape = [key, value];
                    _destinationStateShape.push(_shape);
                });
                setdestinationStateShape(_destinationStateShape);

                Object.keys(category.pickup.city).map((key, value) => {
                    var _shape = [key, value];
                    _pickupCityShape.push(_shape);
                });
                setpickupCityShape(_pickupCityShape);

                Object.keys(category.pickup.state).map((key, value) => {
                    var _shape = [key, value];
                    _pickupStateShape.push(_shape);
                });
                setpickupStateShape(_pickupStateShape);
            }

            setloading(false)
        } catch (err) {
            console.log(err);
            setloading(false)
        }


    }

    const _prepareGenericFunction = (shape, data) => {

        return (
            <div className="grid grid-cols-7 gap-5 rounded p-2 pt-3">
                <div className="card shadow-md col-span-3">
                    <ChartView shape={data} title="List of Leads" chartType="PieChart" />
                </div>
                <div className="col-span-4">
                    <div className="row ml-2 mr-0 mt-4">
                        {_.map(shape, ((val, key) => {
                            return (
                                <div className="card shadow-md p-2 w-72 text-center m-2">
                                    <h3 className="text-2xl mt-2">{val}</h3>
                                    <h5 className="text-sm bold">{key}</h5>
                                </div>
                            );
                        }))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <WrapperProvider
            loading={false}
            title="Analytics"
            description="List of all the disputed orders"
        >
            <div style={{ textAlign: 'left' }} className="ml-3 mb-4">
                <h4 style={{ fontWeight: 'bold' }} className="mb-2">Analytics Section</h4>
                <p className="mb-0">You can find the list of all users here.</p>
            </div>

            <CollapsibleElement title="Leads Category Information" children={() => _prepareGenericFunction(_.omit(dataset?.category?.total, ['_shape']), categoryShape)} />
            <CollapsibleElement title="Leads Activity Information" children={() => _prepareGenericFunction(_.omit(dataset?.lead, ['_shape']), leadShape)} />
            <CollapsibleElement title="User Information" children={() => _prepareGenericFunction(_.omit(dataset?.user), userShape)} />
            <CollapsibleElement title="Destination City Information" children={() => _prepareGenericFunction(_.omit(dataset?.category?.destination.city, ['Custom']), destinationCityShape)} />
            <CollapsibleElement title="Destination State Information" children={() => _prepareGenericFunction(_.omit(dataset?.category?.destination.state, ['Custom']), destinationStateShape)} />
            <CollapsibleElement title="Pickup City Information" children={() => _prepareGenericFunction(_.omit(dataset?.category?.pickup.city, ['Custom']), pickupCityShape)} />
            <CollapsibleElement title="Pickup State Information" children={() => _prepareGenericFunction(_.omit(dataset?.category?.pickup.state, ['Custom']), pickupStateShape)} />


            {/* {!loading &&
                <div className="row m-0 text-center">

                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={leadShape} title="List of Leads" chartType="PieChart" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={userShape} title="Users" chartType="PieChart" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={categoryShape} title="Category Leads" chartType="PieChart" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={destinationCityShape} title="Destination City Leads" chartType="PieChart" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={pickupCityShape} title="Pickup City Leads" chartType="PieChart" />
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={destinationStateShape} title="Destination State Leads" chartType="PieChart" />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card shadow-md">
                            <ChartView shape={pickupStateShape} title="Pickup State Leads" chartType="PieChart" />
                        </div>
                    </div>

                </div>
            } */}

        </WrapperProvider>
    );
}

export default Analytics;