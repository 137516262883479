import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import { TableCell, TableRow } from "@mui/material";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { _call } from "../utils/api";
import { ACCESSKEY, LEADSENUM, LEADSSTATUSEDENUM, ORDERSLISTENUM, USERENUM, WebURL, keyId } from "../utils/_constant";
import _ from 'lodash';
import moment from 'moment';
import { TocryptHash } from "../utils/_function";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const OrderScreen = (props) => {
    const [pageNumber, setpageNumber] = useState(1);
    const [loader, setloader] = useState(false);
    const [getPaymentStatus, setpaymentStatus] = useState({
        state: false,
        data: []
    });
    const [search, setsearch] = useState('');
    const [dataset, setdataset] = useState([]);
    const [fulldataset, setfulldataset] = useState([]);

    useEffect(() => {
        handleData();
    }, [pageNumber]);

    const handleSearch = () => {
        setdataset(fulldataset.filter(x => x.order_number?.includes(search)))
    }


    const handleData = async () => {

        try {
            setloader(true);
            const _filter = {
                pageSize: 20,
                pageNumber: pageNumber
            }
            const _data = await _call(`admin-all-leads/${ACCESSKEY}`, 'PATCH', _filter);
            setfulldataset([...fulldataset, ..._data.data])
            var _omitData = [...dataset, ..._data.data];
            if (!_.isEmpty(_omitData)) {
                _omitData = _omitData.map(item => {
                    item = _.omit(item, ['ltId', 'stId', 'leadId', 'buyer_id', 'order_id']);
                    item.created_on = moment(item.created_on).format('DD-MM-YYYY');
                    return item;
                });
                setdataset(_omitData);
            }
            setloader(false);

        } catch (err) {
            console.log(err);
        }

    }



    const onNavigate = (item) => {

        const findLead = fulldataset?.find(x => x.order_number == item.order_number);
        const encryptCategory = TocryptHash(keyId, item.categoryName);

        const locationurl = `${WebURL}leadview/?lead_id=${findLead?.leadId}&category=${encryptCategory}&serviceId=${findLead?.stId}&location_id=${findLead?.ltId}`;
        const newWindow = window.open(locationurl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }





    const RenderChildren = () => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        onClick={() => onNavigate(row)}
                        key={row.id}
                        className="cursor-pointer"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left">{row.order_number}</TableCell>
                        <TableCell align="left" onClick={() => handleOnProcess(row)}>{row.status == 'Completed' ? 'On Process' : 'Process'}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{row.buyer_name}</TableCell>
                        <TableCell align="left">{row.seller_name}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">{row.dispute_id ? 'Disputed' : 'Fresh'}</TableCell>
                        <TableCell align="left">{row.created_on}</TableCell>

                        <TableCell align="left">{row.pickup_location}</TableCell>
                        <TableCell align="left">{row.destination_location}</TableCell>

                    </TableRow>
                ))}
            </>
        );
    }


    const handleOnProcess = async (_data) => {
        try {

            const findLeadId = fulldataset.find(x => (x.pickup_location == _data.pickup_location && x.order_number == _data.order_number));

            const getEarningStatus = await _call('admin-service/updateStatus/', 'POST', { leadId: findLeadId.leadId });
            setpaymentStatus({
                state: true,
                data: getEarningStatus.data,
                leadId: findLeadId.leadId
            })

        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeStatus = async (item) => {

        try {
            const updateStatus = await _call('admin-service/updateStatus/', 'PUT', { id: item.id, status: !item.is_permitted });
            alert("Updated Successfully")
            setpaymentStatus({
                state: false,
                data: []
            })

        } catch (err) {
            console.log(err);
        }

    }

    const onNext = () => {
        setpageNumber(pageNumber + 1);
    }

    return (
        <Wrapper>

            <div className="row" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-4">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-2">Order List</h4>
                    <p className="mb-0">You can find the list of all users here.</p>
                    <input value={search} onChange={e => setsearch(e.target.value)} className="p-2 mt-2 w-96 rounded shadow-md border" placeholder="Search by order number" />
                    <button className="ml-2 p-2 bg-yellow-500 shadow-md rounded text-white w-24" onClick={handleSearch}>Search</button>
                    <div style={{ overflowX: 'scroll', marginTop: 10 }}>
                        {/* {!loader && !_.isEmpty(dataset) &&
                            <TabularComp
                                onIncrease={() => setpageNumber(pageNumber + 1)}
                                onDecrease={() => pageNumber > 0 && setpageNumber(pageNumber - 1)}
                                data={dataset}
                                onPress={e => onNavigate(e)}
                                columnsData={LEADSSTATUSEDENUM} header="Orders List" />
                        } */}
                        <CuTabularComp onIncrease={onNext} next renderChildren={RenderChildren} data={dataset} columns={ORDERSLISTENUM} />
                    </div>
                </div>

            </div>
            <Modal
                open={getPaymentStatus.state}
                onClose={() => setpaymentStatus({ ...getPaymentStatus, state: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="w-100 h-[50vh]">
                        {getPaymentStatus.data?.map((item, index) => {
                            return <>
                                <h4 className="text-sm ">Current Status: {item.payment_type}</h4>
                                <h4 className="text-sm">Payment Status: {item.status}</h4>
                                <h4 className="text-sm ">Is Permitted: {item.is_permitted ? 'True' : 'False'}</h4>
                                <hr />
                                <button onClick={() => handleChangeStatus(item)} className="bg-slate-50 p-2 w-48 text-center border rounded">Submit</button>
                            </>
                        })}
                    </div>


                </Box>
            </Modal>
        </Wrapper>
    );
}

export default OrderScreen;