import React from 'react';
import { Chart } from "react-google-charts";

const ChartView = ({ shape, title, chartType }) => {
  var data = [
    ["Task", "Hours per Day"],
    ...shape
  ];


  const options = {
    title,
    pieHole: 0.1,
    is3D: true,
  };
  
  return (
    <>
      <Chart
        chartType={chartType}
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </>
  );
};

export default ChartView;