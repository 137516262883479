import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Spacer, Wrapper, WrapperProvider } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, LEADCOLUMNS, LEADSENUM, LEADSSTATUSEDENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from 'moment';
import { useGetHook } from "../utils/_hooks";
import { TableCell, TableRow } from "@mui/material";
import { disputeShape } from "../utils/_shape";
import { useNavigate } from "react-router-dom";


const DisputeScreen = (props) => {
    const [loading, response, error, onRefetch] = useGetHook(disputeShape);
    const [search, setsearch] = useState('');
    const [dataset, setdataset] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        if (!_.isEmpty(response)) {
            setdataset(response);
        }
    }, [response]);

    const onViewDispute = (data) => navigate('/dispute-view', {
        state: {
            data
        }
    });

    const RenderChildren = () => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        onClick={() => onViewDispute(row)}
                        key={row.id}
                        className="cursor-pointer"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.categoryName} {row.subCategoryName}
                        </TableCell>
                        <TableCell align="left">{row.order_number}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">{moment(row.created_on).format('DD-MM-YYYY')}</TableCell>

                        <TableCell align="left">{row.pickup_location}</TableCell>
                        <TableCell align="left">{row.destination_location}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const handleSearch = () => {
        setdataset(response.filter(x => x.order_number?.includes(search)))
    }

    return (
        <WrapperProvider
            loading={loading}

        >
            <div className="row text-left" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-4">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-2">Dispute Section</h4>
                    <p className="mb-0">You can find the list of all users here.</p>
                    <input value={search} onChange={e => setsearch(e.target.value)} className="p-2 mt-2 w-96 rounded shadow-md border" placeholder="Search by order number" />
                    <button className="ml-2 p-2 bg-yellow-500 shadow-md rounded text-white w-24" onClick={handleSearch}>Search</button>
                    <div style={{ overflowX: 'scroll', marginTop: 20 }}>
                        <CuTabularComp renderChildren={RenderChildren} data={dataset} columns={LEADCOLUMNS} onIncrease={() => onRefetch()} />
                    </div>
                </div>

            </div>


        </WrapperProvider>
    );
}

export default DisputeScreen;