import React, { useEffect, useMemo, useState } from "react";
import { CuTabularComp, Wrapper } from "../components";
import { TableCell, TableRow } from "@mui/material";
import { _call } from "../utils/api";
import { ACCESSKEY, LEADSENUM, LEADSLISTENUM, USERENUM, WebURL, keyId } from "../utils/_constant";
import _ from 'lodash';
import moment from "moment";
import { TocryptHash } from "../utils/_function";


const LeadScreen = (props) => {
    const [pageNumber, setpageNumber] = useState(1);
    const [loader, setloader] = useState(false);
    const [search, setsearch] = useState('');
    const [dataset, setdataset] = useState([]);
    const [fulldataset, setfulldataset] = useState([]);

    useEffect(() => {
        handleData();
    }, [pageNumber]);

    const handleData = async () => {

        try {
            setloader(true);
            const _filter = {
                pageSize: 20,
                pageNumber: pageNumber
            }
            const _data = await _call(`admin-all-leads/${ACCESSKEY}`, 'POST', _filter);
            setfulldataset([...fulldataset, ..._data.data])
            var _omitData = [...dataset, ..._data.data];

            if (!_.isEmpty(_omitData)) {
                _omitData = _omitData.map(item => {
                    item = _.omit(item, ['ltId', 'stId', 'leadId']);
                    item.created_on = moment(item.created_on).format('DD-MM-YYYY');
                    return item;
                });
                setdataset(_omitData);
            }
            setloader(false);

        } catch (err) {
            console.log(err);
        }

    }

    const onNavigate = (item) => {
        const findLead = fulldataset?.find(x => x.order_number == item.order_number);
        const encryptCategory = TocryptHash(keyId, item.categoryName);

        const locationurl = `${WebURL}leadview?lead_id=${findLead?.leadId}&category=${encryptCategory}&serviceId=${findLead?.stId}&location_id=${findLead?.ltId}`;
        const newWindow = window.open(locationurl, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    useEffect(() => {
        handleData();
    }, [pageNumber]);


    const handleSearch = () => {
        setdataset(fulldataset.filter(x => x.order_number?.includes(search)))
    }

    const RenderChildren = () => {
        return (
            <>
                {dataset.map((row) => (
                    <TableRow
                        onClick={() => onNavigate(row)}
                        key={row.id}
                        className="cursor-pointer"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="left">{row.order_number}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">{row.created_on}</TableCell>

                        <TableCell align="left">{row.pickup_location}</TableCell>
                        <TableCell align="left">{row.destination_location}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }


    const onNext = () => {
        setpageNumber(pageNumber + 1);
    }

    return (
        <Wrapper
            title="Leads"
            description="You can find the list of all users here."
        >
            <div className="row" style={{ marginTop: -20 }}>
                <div className="col-md-12 ml-4">
                    <h4 style={{ fontWeight: 'bold' }} className="mb-0">Leads List</h4>
                    <p className="mt-0 mb-3">List of all leads. Tap on do necessary actions</p>
                    <input value={search} onChange={e => setsearch(e.target.value)} className="p-2 mt-2 w-96 rounded shadow-md border" placeholder="Search by order number" />
                    <button className="ml-2 p-2 bg-yellow-500 shadow-md rounded text-white w-24" onClick={handleSearch}>Search</button>
                    <div style={{ overflowX: 'scroll', marginTop: 10 }}>

                        <CuTabularComp onIncrease={onNext} next renderChildren={RenderChildren} data={dataset} columns={LEADSLISTENUM} />
                    </div>
                </div>

            </div>

        </Wrapper>
    );
}

export default LeadScreen;