import { List, ListItem } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LOGO, LOGONAME } from '../assets'
import { EmailIcon, LeadIcon, NotificationIcon, OrderIcon, UserIcon } from "../assets/_svgs";
import Spacer from "./_spacer";

const _navOptions = [
    { id: 0, name: 'All Users', icon: UserIcon, route: '/' },
    { id: 1, name: 'Leads', icon: LeadIcon, route: '/leads' },
    { id: 2, name: 'Orders', icon: OrderIcon, route: '/orders' },
    { id: 3, name: 'Emails', icon: NotificationIcon, route: '/email-list' },
    { id: 4, name: 'Help', icon: EmailIcon, route: '/help-list' },
    { id: 5, name: 'Dispute', icon: OrderIcon, route: '/dispute' },
    { id: 6, name: 'Analytics', icon: EmailIcon, route: '/analytics' },
    { id: 6, name: 'Subscription', icon: LeadIcon, route: '/subscription' },
    { id: 7, name: 'Logout', icon: EmailIcon, },
]

const Sidebar = (props) => {
    const navigate = useNavigate();

    const handleNavigate = async(item) => {
        if(item.id == 7){
            localStorage.removeItem('token');
            navigate('/login');
            return;
        }
        navigate(item.route)
    }

    return (
        <div className="sidebar shadow-sm w-100 bg-white h-full pt-3"
        style={{justifyContent:'flex-start', alignItems:'center',display:'flex', flexDirection:'column'}}
        >
            

            <List>
                {_navOptions.map(item => {
                    return (
                        <ListItem onClick={() => handleNavigate(item)} style={{ cursor: 'pointer' }} className="border-gray-100 border-b-2 p-2 mt-3"
                        style={{flexDirection:'column'}}
                        >
                            <div className="ml-4">{item.icon}</div>
                            <h5 className="mt-2 ml-3 text-gray-600 w-100" style={{fontSize:12, textAlign:'center'}}>{item.name}</h5>
                        </ListItem>
                    );
                })}
            </List>

        </div>
    );
}

export default Sidebar;