import React from "react";
import Sidebar from "./_sidebar";
import Header from "./_header";

const Wrapper = (props) => {
    const { children, title, description } = props;
    return (
        <div className="container-fluid m-0 p-0" style={{background:'#f7f7f7'}}>
            <Header title={title} paragraph={description}/>
            <div className="row">
                <div style={{width:100}}>
                    <Sidebar />
                </div>
                <div className="col-md-11 pt-5">
                    {children}
                </div>
            </div>
        </div>
    );
}
export default Wrapper;