import React, { useEffect, useRef, useState } from "react";
import { Spacer, Wrapper } from "../components";
import TabularComp from "../components/_tabularcomp";
import { _call } from "../utils/api";
import { ACCESSKEY, EMAILTOPIC, LEADSENUM, USERENUM } from "../utils/_constant";
import _ from 'lodash';
import moment from "moment";
import { Editor } from '@tinymce/tinymce-react';
import { TextareaAutosize } from '@mui/base';
import { Button, ButtonGroup, FormControl, Input, InputLabel, MenuItem, Select, TextField } from "@mui/material";


const HelpScreen = (props) => {
    const [title, settitle] = useState('');
    const [subtopic, setsubtopic] = useState([]);
    const [article, setarticle] = useState([]);
    const [dataset, setdataset] = useState([]);
    const [sdataset, setsdataset] = useState([]);
    const [mode, setmode] = useState(0);

    useEffect(() => {
        handleData();
    }, [mode]);

    const handleData = async () => {
        try {
            const _get = await _call('topic', 'GET');
            setdataset(_get.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        handleGetTopic();
    }, [article?.topic])

    const handleGetTopic = async () => {
        try {
            const _get = await _call(`subtopic/${article?.topic?.id}`, 'GET');
            setsdataset(_get.data);
        } catch (err) {
            console.log(err);
        }
    }

    const handleSubmit = async () => {

        if (mode == 0 && title == '') {
            alert("Please provide required information")
            return;
        }

        if (mode == 1 && (subtopic?.name == '' || !subtopic?.topic)) {
            alert("Please provide required information")
            return;
        }

        if (mode == 2 && (article?.title == '' || article?.description == '' || !article?.topic || !article?.subtopic)) {
            alert("Please provide required information")
            return;
        }

        try {

            if (mode == 0) {
                const _data = {
                    title,
                }
                const _create = await _call('topic', 'PUT', _data);
                alert('Created Successfully');
            }

            if (mode == 1) {
                const _data = {
                    name: subtopic.name,
                    topicId: subtopic.topic.id,
                }
                const _create = await _call('create-subtopic', 'PUT', _data);
                handleGetTopic()
                alert('Created Successfully');
            }

            if (mode == 2) {
                const _data = {
                    title: article.title,
                    description: article.description,
                    topicId: article.topic.id,
                    subtopicId: article.subtopic.id,
                }
                const _create = await _call('create-article', 'PUT', _data);
                alert('Created Successfully');
            }



        } catch (err) {
            console.log(err);
            alert('Some problem occured');
        }

    }


    return (
        <Wrapper>
            <h1 className="bold mb-0 text-4xl ml-2 mt-1">Create Help
            </h1>
            <p className="bold mb-0 ml-2 mt-1">You can find the list of all emails here.</p>

            <div className="container mt-5">
                <div className="col-md-8 offset-md-2">
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                        <Button onClick={() => setmode(0)} style={{ backgroundColor: mode == 0 ? 'white' : 'teal', color: mode == 0 ? 'teal' : 'white' }}>Topic</Button>
                        <Button onClick={() => setmode(1)} style={{ backgroundColor: mode == 1 ? 'white' : 'teal', color: mode == 1 ? 'teal' : 'white' }}>Sub Topic</Button>
                        <Button onClick={() => setmode(2)} style={{ backgroundColor: mode == 2 ? 'white' : 'teal', color: mode == 2 ? 'teal' : 'white' }}>Article</Button>
                    </ButtonGroup>
                    <Spacer top={30} />
                    {mode == 0 ?
                        <FormControl fullWidth>
                            <TextField id="outlined-basic"
                                value={title}
                                onChange={e => settitle(e.target.value)}
                                label="Title" variant="outlined" />
                        </FormControl>
                        : mode == 1 &&
                        <>
                            <FormControl fullWidth>
                                <TextField id="outlined-basic"
                                    value={subtopic?.name}
                                    onChange={e => setsubtopic({ ...subtopic, name: e.target.value })}
                                    label="Title" variant="outlined" />
                            </FormControl>
                            <Spacer top={20} />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Topic</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Topic"
                                    onChange={e => setsubtopic({ ...subtopic, topic: e.target.value })}
                                >
                                    {dataset.map(item => <MenuItem value={item}>{item.title}</MenuItem>)}
                                </Select>
                            </FormControl>

                        </>
                    }
                    {mode == 2 &&
                        <>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Topic</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Topic"
                                    onChange={e => setarticle({ ...article, topic: e.target.value })}
                                >
                                    {dataset.map(item => <MenuItem value={item}>{item.title}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {!_.isEmpty(sdataset) &&
                                <>
                                    <Spacer top={20} />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sub Topic</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Sub Topic"
                                            onChange={e => setarticle({ ...article, subtopic: e.target.value })}
                                        >
                                            {sdataset.map(item => <MenuItem value={item}>{item.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </>
                            }
                            <Spacer top={20} />
                            <FormControl fullWidth>
                                <TextField id="outlined-basic"
                                    value={article?.title}
                                    onChange={e => setarticle({ ...article, title: e.target.value })}
                                    label="Title" variant="outlined" />
                            </FormControl>
                            <Spacer top={20} />
                            <FormControl fullWidth>
                                <TextareaAutosize value={article.description} onChange={e => setarticle({ ...article, description: e.target.value })} minRows={10} placeholder="Description" className="form-control" />
                            </FormControl>
                            <Spacer top={20} />

                        </>
                    }
                    <Spacer top={30} />
                    <Button onClick={handleSubmit} variant="contained" fullWidth className="p-3">Save</Button>
                </div>
            </div>
        </Wrapper>
    );
}

export default HelpScreen;